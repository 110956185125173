<template>
  <div class="container">
    <div class="nav-top">
      <img src="../assets/logo.png" class="logo-style"/>
      <div class="about-us">
        <img src="../assets/home_image_about@2x.png" class="logo-two" @click="goUs"/>
        <img src="../assets/home_image_backstage@2x.png" class="logo-two-style" @click="goHou"/>
      </div>
    </div>
    <div class="container-image">
      <img src="../assets/home_image_one@2x.png" class="png-style"/>
      <img src="../assets/home_image_two@2x.png" class="png-style"/>
      <img src="../assets/home_image_three@2x.png" class="png-style"/>
      <img src="../assets/home_image_four@2x.png" class="png-style"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageA',
  methods:{
    goUs(){
      this.$router.push('/aboutUs')
    },
    goHou(){
      window.open("https://klvs.com.cn/admin/login","_blank")
    }
  }
}
</script>

<style scoped>
.container{
  width:94%;
  margin:0 auto;
  height:100%;
}
.nav-top{
  height:108px;
  padding:0 8%;
  width:78%;
  background-color: rgba(0,0,0,.2);
  position: fixed;
  top: 0;
  left: 3%;
  z-index: 100000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.logo-style{
  width:214px;
  height:60px;
}
.logo-two{
  width: 146px;
  height:48px;
  margin-right: 30px;
  cursor: pointer;
}
.logo-two-style{
  width: 146px;
  height:48px;
  cursor: pointer;
}
.png-style{
  width:100%;
  height:auto;
  display: block;
}
.about-us{
  cursor: pointer;
}
</style>
