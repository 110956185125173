// 引入路由
import { createRouter, createWebHistory } from 'vue-router'
 
import PageA from '../views/PageA'
import PageB from '../views/PageB'
 
// 创建路由
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            component: PageA
        },
        {
            path: "/index",
            component: PageA
        },
        {
            path: "/PageA",
            component: PageA
        },
        {
            path: "/aboutUs",
            component: PageB
        }
    ]
})
 
// 导出路由
export default router;
